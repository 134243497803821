<template>
  <div class="mb-5" v-if="userData">
    <v-form v-model="isValid" ref="form" @submit.prevent>
      <v-row align="center">
        <!-- COUNTRY -->
        <!-- ENABLED ONLY ON REGISTRATION WITHOUT CARD-->
        <v-col cols="12" sm="6">
          <label v-html="`${$t('profile.address.country')} *`" />
          <v-select
            :placeholder="`${$t('profile.address.country')}`"
            v-model="userData.billingAddress.country"
            :items="countries"
            return-object
            item-text="name"
            item-value="name"
            required
            outlined
            dense
          />
        </v-col>
        <!-- PROVINCE -->
        <v-col cols="12" sm="6">
          <label v-html="`${$t('profile.address.province')} *`" />
          <v-select
            :id="'id_' + Math.random()"
            :placeholder="`${$t('profile.address.province')}`"
            v-model="userData.billingAddress.province"
            :items="provinces"
            return-object
            item-text="name"
            item-value="name"
            outlined
            dense
          ></v-select>
        </v-col>
        <!-- CITY -->
        <v-col cols="12" sm="6">
          <label v-html="`${$t('profile.address.city')} *`" />
          <v-text-field
            :placeholder="`${$t('profile.address.city')}`"
            v-model="userData.billingAddress.city"
            :rules="[requiredRules()]"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" />
        <!-- ADDRESS1 -->
        <v-col cols="12" sm="6">
          <label v-html="`${$t('profile.address.address')} *`" />
          <v-text-field
            :placeholder="`${$t('profile.address.address')}`"
            v-model="userData.billingAddress.address1"
            :rules="[requiredRules()]"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <!-- ADDRESS NUMBER -->
        <v-col cols="12" sm="2">
          <label v-html="`${$t('profile.address.addressNumber')} *`" />
          <v-text-field
            :placeholder="`${$t('profile.address.addressNumber')}`"
            v-model="userData.billingAddress.addressNumber"
            :rules="[requiredRules()]"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <!-- POSTAL CODE -->
        <v-col cols="12" sm="2">
          <label v-html="`${$t('profile.address.postalcode')} *`" />
          <v-text-field
            :placeholder="`${$t('profile.address.postalcode')}`"
            v-model="userData.billingAddress.postalcode"
            :rules="[requiredRules()]"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>

        <!-- HOME PHONE -->
        <v-col cols="12" sm="6">
          <label v-html="`${$t('profile.contacts.homePhone')}`" />
          <v-text-field
            :placeholder="`${$t('profile.contacts.homePhone')}`"
            v-model="userData.contact.homePhone"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <!-- MOBILE PHONE -->
        <v-col cols="12" sm="6">
          <label v-html="`${$t('profile.contacts.mobilePhone')}`" />
          <v-text-field
            :placeholder="`${$t('profile.contacts.mobilePhone')}`"
            v-model="userData.phone"
            :rules="mobilePhoneRules"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <!-- PREFFERED MARKET-->
        <v-col cols="12" sm="6">
          <label v-html="$t('profile.contacts.prefferedMarket')" />
          <v-autocomplete
            v-if="userData.defaultStoreAddress"
            v-model="userData.defaultStoreAddress.addressId"
            :placeholder="$t('profile.contacts.prefferedMarket')"
            :items="warehouses"
            item-value="address.addressId"
            item-text="name"
            outlined
            dense
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { requiredValue, isMobilePhone } from "~/validator/validationRules";
import addressService from "~/service/addressService";
import aliRegistrationService from "@/service/aliRegistrationService";

export default {
  name: "UserAddress",
  props: {
    isFieldDisabled: { type: Boolean, default: false },
    userData: { type: Object, required: true },
    supportData: { type: Object, required: false },
    isProfileForm: { type: Boolean, default: false },
    response: { type: Object }
  },
  data() {
    return {
      isValid: false,
      warehouses: null,
      countries: null,
      provinces: null,
      bornCountry: null,
      requiredRules: requiredValue,
      mobilePhoneRules: [isMobilePhone()]
    };
  },

  methods: {
    /* changeProvince(province) {
      this.userData.billingAddress.province = province;
    },*/
    validate() {
      let _this = this;
      setTimeout(function() {
        if (_this.$refs.form) {
          _this.valid = _this.$refs.form.validate();
          if (_this.isProfileForm) {
            _this.$emit("validate", _this.valid);
          }
        }
      }, 200);
    },
    async findWarehouse() {
      const res = await addressService.findWarehouse();
      this.warehouses = res.warehouses;
      if (
        this.userData &&
        this.userData.defaultStoreAddress &&
        this.userData.defaultStoreAddress.addressId
      ) {
        this.userData.defaultStoreAddressId = this.userData.defaultStoreAddress.addressId;
      }
    },
    async loadCountriesList() {
      this.countries = await aliRegistrationService.getCountries();
    },
    async loadProvinceList() {
      this.provinces = await aliRegistrationService.provinceList(false);
    },
    /*selectCountry(country) {
      let _this = this;
      _this.userData.ResCountry = country.Name;
      this.resetProvince();
    },*/
    back() {
      this.$emit("back");
    },
    submit() {
      this.$emit("submit", this.userData, this.supportData);
    }
  },
  async mounted() {
    if (this.userData.billingAddress && !this.userData.billingAddress.country) {
      this.userData.billingAddress["country"] = {};
    }
    if (!this.userData.defaultStoreAddress) {
      this.userData.defaultStoreAddress = {
        addressId: undefined
      };
    }
    if (!this.userData.contact) {
      this.userData.contact = {};
    }
    this.findWarehouse();
    this.loadCountriesList();
    this.loadProvinceList();
  }
};
</script>
